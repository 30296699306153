<template>
  <div>
    <!-- {{ societies }} -->
    <!-- <form> -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="fullName">Nom complet</label>
          <input
            type="text"
            class="form-control"
            id="fullName"
            v-model="societies.fullName"
          />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="logo"> Logo </label>
          <!-- <input
              type="text"
              class="form-control"
              id="logo"
              v-model="societies.logo"
            /> -->
          <input
            type="file"
            class="form-control"
            id="logo"
            name="logo"
            @change="onFileChange"
          />

          <img v-if="imageUrl" :src="imageUrl" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="capitalization">Capitalisation</label>
          <input
            type="number"
            class="form-control"
            id="capitalization"
            v-model="societies.capitalization"
          />
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="address">Adresse : </label>
          <input
            type="text"
            class="form-control"
            id="address"
            v-model="societies.address"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="city">Ville</label>
          <input
            type="text"
            class="form-control"
            id="city"
            v-model="societies.city"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="postalCode">Code postal</label>
          <input
            type="text"
            class="form-control"
            id="postalCode"
            v-model="societies.zipCode"
          />
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="phoneNumberA">Téléphone (1)</label>
          <input
            type="text"
            class="form-control"
            id="phoneNumberA"
            v-model="societies.phoneA"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="phoneNumberB">Téléphone (2)</label>
          <input
            type="text"
            class="form-control"
            id="phoneNumberB"
            v-model="societies.phoneNumberB"
          />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="fax">Fax</label>
          <input
            type="text"
            class="form-control"
            id="fax"
            v-model="societies.fax"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="cnss">Numéro CNSS</label>
          <input
            type="text"
            class="form-control"
            id="cnss"
            v-model="societies.cnss"
          />
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="ice">Numéro ICE</label>
          <input
            type="text"
            class="form-control"
            id="ice"
            v-model="societies.ice"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="rc">Numéro RC</label>
          <input
            type="text"
            class="form-control"
            id="rc"
            v-model="societies.rc"
          />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="if">Numéro IF</label>
          <input
            type="text"
            class="form-control"
            id="if"
            v-model="societies.if"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="tp">Numéro TP</label>
          <input
            type="text"
            class="form-control"
            id="tp"
            v-model="societies.tp"
          />
        </div>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="bank"> Banque </label>
          <input
            type="text"
            class="form-control"
            id="bank"
            v-model="societies.bank"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="rib">Nº RIB </label>
          <input
            type="text"
            class="form-control"
            id="rib"
            v-model="societies.rib"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col"></div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="save(societies)" class="btn btn-success">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
    <br />
    <br />
    <!-- </form> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  societies() {
    return {
      image: null,
      imageUrl: null,
    };
  },
  computed: {
    ...mapGetters("society", {
      societies: "getSocieties",
    }),
  },
  mounted() {
    this.$store.dispatch("society/fetchSocieties");
  },
  methods: {
    async save(data) {
      let formData = new FormData();

      // formData.append("logo", data.logo);
      formData.append("logo", this.image);
      formData.append("reference", data.reference);
      formData.append("fullName", data.fullName);
      formData.append("capitalization", data.capitalization);
      formData.append("city", data.city);
      formData.append("zipCode", data.zipCode);
      formData.append("phoneA", data.phoneA);
      formData.append("ice", data.ice);
      formData.append("rc", data.rc);
      formData.append("if", data.if);
      formData.append("tp", data.tp);
      formData.append("phoneB", data.phoneB);
      formData.append("bank", data.bank);
      formData.append("rib", data.rib);

      return await this.$store.dispatch("society/update", {
        reference: data.id,
        formData,
      });
    },
    async refresh() {
      return await this.$store.dispatch("society/fetchSocieties");
    },

    onFileChange(e) {
      this.image = e.target.files[0];
    },
  },
};
</script>
